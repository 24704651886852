<template>
	<div class="site" v-title="'千家美装饰官网-室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
		<!-- 横幅背景图 -->
		<Banner></Banner>
 		<!-- 整装 -->
		<div class="whole  " @mouseenter="noActive=true" @mouseleave="noActive=false">
			<div class="carousel" >
				<swiper
					class="swiper"
					:options="swiperOption1"
					ref="mySwiper1"
				>
					<swiper-slide>
                        <div class="img-box">
                            <img
                                @click="navToPage(0)"
                                src="../image/index/index_whole_ban1.jpg"
                                alt=""
                                class="img"
                            />
                        </div>
                        <div   class="info1 common f-y-c-s">
                            <span @click="navToPage(0)" class="text1">HOME</span>
                            <span @click="navToPage(0)" class="text1">DECORATION</span>
                            <span @click="navToPage(0)" class="text2">整体家装</span>
                            <div class="cus-line "></div>
                            <div class="tag1 f-x-b-c">
                                <div class="tag-item">设计</div>
                                <div class="tag-item">人工</div>
                                <div class="tag-item">主材</div>
                                <div class="tag-item">辅材</div>
                                <div class="tag-item">家具</div>
                                <div class="tag-item">灯具</div>
                            </div>
                            <div class="tag2 f-x-b-c ">
                                <div class="tag-item f-x-c-c">实景体验</div>
                                <div class="tag-item f-x-c-c">一流品质</div>
                                <div class="tag-item f-x-c-c">人性化功能</div>
                            </div>
                        </div>
					</swiper-slide>
					<swiper-slide>
                        <div class="img-box">
                            <img
                                @click="navToPage(1)"
                                src="../image/index/index_whole_ban2.jpg"
                                alt=""
                                class="img"
                            />
                        </div>
						
                        <div   class="info2 common f-y-c-s">
                            <span @click="navToPage(1)" class="text1">PERSONALIZATION </span>
                            <span @click="navToPage(1)" class="text1">CUSTOMIZATION</span>
                            <span @click="navToPage(1)" class="text2">个性化定制整装</span>
                            <div class="cus-line "></div>
                            <div class="tag1 f-x-b-c">
                                <div class="tag-item">提供个性化整装解决方案，多元化选择方案</div>
                            </div>
                            <div class="tag2 f-x-b-c ">
                                <div class="tag-item f-x-c-c">设计有态度</div>
                                <div class="tag-item f-x-c-c">生活有温度</div>
                                <div class="tag-item f-x-c-c">定制理想家</div>
                            </div>
                        </div>
					</swiper-slide>
					<swiper-slide>
                        <div class="img-box">
                            <img
                                @click="navToPage(2)"
                                src="../image/index/index_whole_ban3.jpg"
                                alt=""
                                class="img"
                            />
                        </div>
                        <div   class="info3 common f-y-c-s">
                            <span @click="navToPage(2)" class="text1">SOFT OUTFIT</span>
                            <span @click="navToPage(2)" class="text1">MATCHING</span>
                            <span @click="navToPage(2)" class="text2">软装搭配设计</span>
                            <div class="cus-line "></div>
                            <div class="tag1 f-x-b-c">
                                <div class="tag-item">创作空间的整体环境、功能意境层次感</div>
                            </div>
                            <div class="tag2 f-x-b-c ">
                                <div class="tag-item f-x-c-c">空间布局</div>
                                <div class="tag-item f-x-c-c">色彩搭配</div>
                                <div class="tag-item f-x-c-c">创意单品</div>
                            </div>
                        </div>
					</swiper-slide>
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
            <div class="options f-x-s-c">
                <div @click="handelOption('pre')" @mouseenter="noActive=false" @mouseleave="noActive=true" class="btn btn-left f-x-c-c mr34">
                    <i class="el-icon-arrow-left img1"></i>
                </div>
                <div @click="handelOption('next')" class="btn  f-x-c-c" :class="{'btn-active':noActive}">
                    <i class="el-icon-arrow-right img1"></i>
                </div>
            </div>
		</div>
		<!-- 局改焕新家 -->
		<div @click="navToPart" class="part f-y-c-s">
            <div class="mask"></div>
			<span class="title">局改焕新家</span>
			<div class="cus-line "></div>
			<span class="text ">卫生间、厨房、墙面 7天焕新家</span>
		</div>
		<!-- 样板间 -->
		<div class="sample-rom" >
			<IconHeader
				text1="新锐设计风格样板间"
				text2="高颜值、高性价比，把“美”带进千家万户"
				@callback="nvgaToSamplerom"
			></IconHeader>
			<div class="carousel" >
				<swiper
					class="swiper"
					:options="swiperOption2"
					ref="mySwiper2"
                    @click="nvgaToSampleromDetail($event)"
				>
					<swiper-slide
						v-for="(item, index) in templateList"
						:key="index"

					>
						<div
                            :data-item-id="item.id"
							class="slide-item"
							:style="{
								background: `center/cover url(${item.coverUrl})`,
							}"
						>
							<div class="mask"></div>
							<div class="item-box f-y-b-s">
								<span class="title">{{ item.title }}</span>
								<img
									class="img"
									src="../image/index/index_sample.png"
									alt=""
								/>
								<div class="f-x-b-e bottom">
									<HouseInfo :data="item" :showHouseArea="false"></HouseInfo>
									<img
										class="img2"
										src="../image/index/index_sample2.png"
										alt=""
									/>
								</div>
							</div>
							<div class="hover-btn"></div>
							
						</div>
					</swiper-slide>

					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
                <div v-if="templateList.length>1" @click="templateNext" class="mobile-btn f-x-c-c">
                    <img
                        class="img"
                        src="../image/index/index_sample_btn.png"
                        alt=""
                    />
                </div>
			</div>
            <div  @click="nvgaToSamplerom" class="more-btn">
                MORE+
            </div>
		</div>
		<!-- 装修案例 -->
		<div class="template">
			<IconHeader
				text1="装修案例"
				text2="真实呈现 | 你家也可以这样装"
				@callback="nvgaToCase"
			></IconHeader>
            <!-- pc展示 -->
            <div class="template-list1 f-x-b-c">
                <TemplateItem 
                    v-for="(item,index) in caseList.slice(0,2)"
                    :key="index"
                    :data="item"
                    style="width:49%"
                    adUnitCode="MObN"
                    >
                </TemplateItem>
            </div>
			<div class="carousel1" v-if="caseList.slice(2,7).length">
				<swiper
					class="swiper"
					:options="swiperOption4"
					ref="mySwiper4"
				>
					<swiper-slide
						v-for="(item, index) in caseList.slice(2,7)"
						:key="index"
					>
                        <TemplateItem 
                            :key="index"
                            :data="item"
                            type="2"
                            adUnitCode="MObN"
                            >
                        </TemplateItem>
					</swiper-slide>

					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
            <!-- 移动端展示 -->
            <div class="template-list2 f-x-b-c">
                <TemplateItem 
                    v-for="(item,index) in caseList.slice(0,1)"
                    :key="index"
                    :data="item"
                    adUnitCode="MObN"
                    >
                </TemplateItem>
            </div>
			<div class="carousel2 mt20" v-if="caseList.slice(1,6).length">
				<swiper
					class="swiper"
					:options="swiperOption5"
					ref="mySwiper5"
				>
					<swiper-slide
						v-for="(item, index) in caseList.slice(1,6)"
						:key="index"
					>
                        <TemplateItem 
                            :key="index"
                            :data="item"
                            type="2"
                            adUnitCode="MObN"

                            >
                        </TemplateItem>
					</swiper-slide>

					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>
            <div @click="nvgaToCase" class="more-btn">
                MORE+
            </div>
		</div>
        <!-- 品牌新闻 -->
        <div class="news" >
            <IconHeader
				text1="品牌新闻"
				text2="快速了解品牌动态新闻"
				@callback="nvgaToNews"
			></IconHeader>
            <div class="box">
                <div class="video">
                    <iframe
                        src="//v.qq.com/iframe/player.html?vid=q33485l7na4"
                        scrolling="no"
                        border="0"
                        frameborder="no"
                        framespacing="0"
                        allowfullscreen="true"
                        width="100%"
                        pic="@/image/bg01.jpg"
                        height="100%"
                        >
                    </iframe>
                </div>
                <div v-if="newsList.length" class="list f-y-c-s">
                    <div
                        @click="nvgaToNewsDetail(item.articleId)"
                        v-for="(item,index) in newsList"
                        :key="index"
                        class="list-item f-x-b-c"
                    >
                        <div class="info f-y-c-s">
                            <div class="title">{{item.title}}</div>
                            <span class="time">{{item.createTime && (item.createTime).slice(0,10)}}</span>
                        </div>
                        <i  class="right el-icon-right"></i>
                    </div>
                </div>
                <div @click="nvgaToNews" class="more-btn">
                    MORE+
                </div>
            </div>
        </div>
        
        <Flow></Flow>
        <!-- 底部 -->
        <Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
        
	</div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/NavBar.vue'
import Banner from '../components/HomeBanner.vue'
import Footer from '../components/Footer.vue'

import HouseInfo from '../components/HouseInfo.vue'
import IconHeader from '../components/IconHeader.vue'
import TemplateItem from '../components/TemplateItem.vue'
import Flow from '@/components/Flow.vue'

import { postForm } from '@/api/App.js'
import { getmedia } from '@/api/advertising/advertising.js'
import { getactivity, getactivityId } from '@/api/views/name.js'
import { getTemplateData } from '@/api/template.js'
import { getCaseData } from '@/api/case.js'
import { getNewsData } from '@/api/news.js'


export default {
	name: 'Home',
	components: {
		Footer,
		Banner,
		NavBar,
		HouseInfo,
		IconHeader,
        TemplateItem,
        Flow
	},
	async mounted() {
        console.log("this.$route.path",this.$route.path);
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            metaKeywords.setAttribute('content', '千家美装饰,室内装修公司,房屋装修设计,家装设计,装修报价,家装公司');
        }
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', '千家美装饰——专注于高品质家装，是中国大型的装饰装修公司，高端进口欧标材料，海量精美装修案例，量身定制装修设计师，致力于提供高端、环保的一站式室内装饰装修服务。');
        }
        // this.getmedia()
        //样板间列表
		this.getCaseList()
        // 装修案例列表
		this.getTemplateList()
        this.getNewsList()
		// 获取当前页面的title
		var title = document.title
		// 把上面的数据提交给后台
		postForm(title).then(() => {
			console.log('提交成功')
		})
	},
	data() {
		return {
            noActive:false,
            swiperOption: {
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
			activityCode: '', // 营销活动代码
			planCode: '', // 营销计划也就是广告计划的代码
			mediaSource: '', // 媒体来源代码
			adsPlanName: '', // 营销计划名称
			mediaSourceName: '', // 媒体名称

			caseList: [],
			templateList: [],
            newsList:[],
			swiperOption1: {
				pagination: {
					el: '.whole .swiper-pagination',
					type: 'custom',
					renderCustom: function (swiper, current, total) {
						const paginationHtml = `
                            <div class="swiper-pagination-customs swiper-pagination-customs-activ">
                                <div class="text1">${current}</div>
                                <div class="text2 "></div>
                                <div class="text3">${total}</div>
                            </div>`
						return paginationHtml
					},
				},
			},
			swiperOption2: {
                initialSlide:0,
				slidesPerView: 'auto',
				spaceBetween: '5%',
                loop:true,
                loopedSlides:3,
                // observer: true,
				// centeredSlides: true,
			},
			swiperOption3: {
				pagination: {
					el: '.template .swiper-pagination',
					// type: 'bullets',
					//type: 'fraction',
					//type : 'progressbar',
					type: 'custom',
					renderCustom: function (swiper, current, total) {
						var paginationHtml = ''
						for (var i = 0; i < total; i++) {
							// 判断是不是激活焦点，是的话添加active类，不是就只添加基本样式类
							if (i === current - 1) {
								paginationHtml +=
									'<div class="swiper-pagination-customs swiper-pagination-customs-activ"></div>'
							} else {
								paginationHtml +=
									'<div class="swiper-pagination-customs"></div>'
							}
						}
						return paginationHtml
					},
				},
			},
            swiperOption4: {
				slidesPerView: 3,
				spaceBetween: '2%',
				// centeredSlides: true,
			},
            swiperOption5:{
                slidesPerView: 1.5,
				spaceBetween: '5%',
                // centeredSlides: true,
            },
            test:false
		}
	},
	methods: {
        navToPage(index){
            switch (index) {
                case 0 :
                    this.$router.push({
                        path:'/decoration/whole'
                    })
                    break
                case 1 :
                    this.$router.push({
                        path:'/decoration/bardian'
                    })
                    break
                case 2 :
                    this.$router.push({
                        path:'/decoration/collocation'
                    })
                    break
            }
        },
		async getTemplateList() {
			try {
				const params = {
					current: 1,
					size: 6,
					showHomePage: 1,
				}
				const res = await getTemplateData(params)
				if (res.code == 0) {
                    this.templateList = res.data.records
                    this.swiperOption2.loop = res.data.records.length>1
				}
			} catch (err) {
				console.log(err)
			}
		},
		async getCaseList() {
			try {
				const params = {
					current: 1,
					size: 6,
					showHomePage: 1,
				}
				const res = await getCaseData(params)
				if (res.code == 0) {
					this.caseList = res.data.records
				}
			} catch (err) {
				console.log(err)
			}
		},
        async getNewsList() {
			try {
				const params = {
					current: 1,
					size: 3,
				}
				const res = await getNewsData(params)
				if (res.code == 0) {
					this.newsList = res.data.records
				}
			} catch (err) {
				console.log(err)
			}
		},
        templateNext(){
            this.$refs.mySwiper2.$swiper.slideNext()
        },
		handelOption(data) {
            console.log("data",data);
			if (data == 'pre')
				this.$refs.mySwiper1.$swiper.slidePrev()
			if (data == 'next')
				this.$refs.mySwiper1.$swiper.slideNext()
		},

		// 跳转样板间
		nvgaToSamplerom() {
            this.$router.push({
                path:'/template',
            })
        },
        nvgaToSampleromDetail(event) {
            const clickedItem = event.target.closest('.sample-rom .slide-item');
            const id = clickedItem.getAttribute('data-item-id');
            this.$router.push({
                path:'/template/detail',
                query:{
                    id
                }
            })
        },
		nvgaToCase() {
            this.$router.push({
                path:'/investor',
            })
        },
       
        
		nvgaToNews() {
            this.$router.push({
                path:'/about/news',
            })
        },
		nvgaToNewsDetail(id) {
            this.$router.push({
                path:'/about/news-detail',
                query:{
                    id
                }
            })
        },
		navToPart(){
            this.$router.push({
                path:'/decoration/part',
            })
        },

		// 先获取所有的媒体代码
		getmedia() {
			getmedia().then((data) => {
				let re = ''
				data.data.records.forEach((obj) => {
					if (obj.sourceCode === 'website') {
						re = obj.partnersCode
						this.mediaSource = obj.partnersCode
						this.mediaSourceName = obj.partnersName
					}
				})
				// 请求营销计划的官网分页
				getactivity(re).then((data) => {
					getactivityId(data.data.id).then((data) => {
						this.activityCode = data.data.activityCode
						this.planCode = data.data.planCode
						this.adsPlanName = data.data.planName
						// 存储营销计划Code
						if (!this.$Cookie.get('projectCode')) {
							this.$Cookie.set(
								'projectCode',
								data.data.planCode,
								{
									expires: 180,
								}
							)
						}
						// 存储渠道代码
						if (!this.$Cookie.get('channelCode')) {
							this.$Cookie.set('channelCode', 'website', {
								expires: 180,
							})
						}
						// 存储媒体代码
						if (!this.$Cookie.get('mediaCode')) {
							this.$Cookie.set(
								'mediaCode',
								data.data.partnersList[0].partnersCode,
								{
									expires: 180,
								}
							)
						}
						// 存储营销活动代码
						if (!this.$Cookie.get('activityCode')) {
							this.$Cookie.set(
								'activityCode',
								data.data.activityCode,
								{
									expires: 180,
								}
							)
						}
					})
				})
			})
		},
	},
}
</script>

<style scoped lang="less">
@media screen and (max-width: 899px) {
	.site {
		height: 100%;
		margin: 0;
		overflow-x:  hidden;
		position: relative;
        
		// 整装
		.whole {
            margin-top: 50px;
			width: 100%;
			// height: 330px;
			// background: pink;
            display: flex;
            flex-flow: column;
            position: relative;
			.carousel {
                width: 350px;
				
				overflow: hidden;
				background: #fff;
                display: flex;
                justify-content: start;
				.swiper {
					// background: #46ff56;
                    .img-box{
                        border-radius: 0px 60px 60px 0px;
                        height: 330px;
                        overflow: hidden;
                        width: 100%;
                        .img{
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
                    .info1{
                        .cus-line{
                            width: 139px;
                        }
                        
                    }
                    .info2{
                        .cus-line{
                            width: 245px;
                        }
                        
                    }
                    .info3{
                        .cus-line{
                            width: 210px;
                        }
                        
                    }
                    .common {
                        width: 100%;
                        padding: 20px 13px 90px 13px;
                        height: 365px;
                        box-sizing: border-box;
                        justify-content: flex-start;
                        .text1 {
                            // height: 126px;
                            font-family: Source Han Sans SC;
                            font-weight: 300;
                            font-size: 24px;
                            color: #1b1d22;
                            line-height: 24px;
                            margin-bottom: 14px;
                        }
                        .text2 {
                            height: 34px;
                            line-height: 34px;
                            
                            font-weight: bold;
                            font-size: 35px;
                            color: #1b1d22;
                        }
                        .cus-line {
                            height: 5px;
                            background: #753CBE;
                            margin-top: 12px;
                        }
                        .tag1 {
                            margin-top: 14px;
                            width: 100%;
                            .tag-item {
                                
                                font-weight: bold;
                                font-size: 18px;
                                color: #1b1d22;
                            }
                        }
                        .tag2 {
                            margin-top: 16px;
                            width: 100%;
                            .tag-item {
                                width: 94px;
                                height: 35px;
                                border: 1px solid #e5e5e5;
                                
                                font-weight: 400;
                                font-size: 16px;
                                color: #1b1d22;
                            }
                        }
                        
                    }
                    /deep/ .swiper-pagination-custom{
                        top: 270px;
                        right: 0px;
                    }
					/deep/ .swiper-pagination-customs {
						display: flex;
						justify-content: end;
                        align-items: center;
						padding-right: 30px;
						box-sizing: border-box;
						.text1 {
							height: 50px;
							font-family: DIN;
							font-weight: bold;
							font-size: 63px;
							color: #ffffff;
							line-height: 1px;
						}
						.text2 {
							width: 3px;
							height: 40px;
							line-height: 25px;
							background: #ffffff;
							transform: skew(-30deg);
                            margin-left: 10px;
                            margin-right: 15px;
							// margin-top: -5px;
						}
						.text3 {
							width: 20px;
							height: 35px;
							font-family: DIN;
							font-weight: 400;
							font-size: 47px;
							color: #ffffff;
							line-height: 40px;
						}
					}
				}
			}
            .options {
                position: absolute;
                bottom: 20px;
                left: 13px;
                margin-top: 21px;
                z-index: 9;
                .btn {
                    width: 63px;
                    height: 62px;
                    border-radius: 50%;
                    border: 1px solid #e5e5e5;
                    cursor: pointer;
                    .img1 {
                        font-size: 30px;
                        font-weight: bold;
                    }
                }
                .btn:hover {
                    border: 1px solid #753cbe;
                    .img1 {
                        color: #753cbe;
                    }
                }
            }
            
		}
		// 局改焕新家
		.part {
            // border-radius: 15px;
            margin-top: 5px;
			height: 240px;
			width: 100%;
			background:no-repeat center/cover url(../image/index/index_part_bg.jpg);
			padding: 0 14px;
			box-sizing: border-box;
            position: relative;
            overflow: hidden;
            .mask{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
            }
			.title {
                z-index: 9;
				height: 35px;
				
				font-weight: bold;
				font-size: 35px;
				color: #ffffff;
				line-height: 35px;
			}
			.cus-line {
                z-index: 9;
                margin-top: 18px;
				width: 173px;
                height: 5px;
                background: #753CBE;
			}
			.text {
                z-index: 9;
                margin-top: 16px;
				
				font-weight: 400;
				font-size: 20px;
				color: #ffffff;
				line-height: 20px;
			}
		}
		// 样板间
		.sample-rom {
            margin-top:50px;
			// height: 1100px;
			width: 100%;
			box-sizing: border-box;
			.carousel {
				height: 220px;
                margin-top: 24px;
                position: relative;
				.swiper {
                    padding: 0 13px;
					height: 100%;
					width: 100%;
                    box-sizing: border-box;
					/deep/.swiper-slide:last-child {
                        width: 350px;
					}
                    .swiper-slide{
                        width: 300px;
                    }
					.slide-item::after:hover {
						cursor: pointer;
					}
					.slide-item {
                        width: 300px;
						height: 100%;
						position: relative;
						border-radius: 12px;
						.item-box {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 2;
							height: 100%;
							padding: 11px 14px;
							border-radius: 12px;
							width: 100%;
							height: 100%;
							box-sizing: border-box;
                            .title {
                                width: 200px;
                                
                                font-weight: bold;
                                font-size: 15px;
                                color: #ffffff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .img {
                                width: 90px;
                                height: 90px;
                                opacity: 0.8;
                                margin: 0 auto;
                            }
							.bottom {
								width: 100%;
								.img2 {
									width: 16px;
									height: 16px;
                                    margin-bottom: 5px;
								}
							}
						}
						.hover-btn {
							position: absolute;
							top: 0;
							right: -10px;
							width: 50px;
							height: 100%;
							// background:pink;
							// z-index: 3;
						}
						.hover-btn:hover {
							cursor: url('../image/index/test.jpg'), auto;
						}
                       
						.mask {
							z-index: 1;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							background: rgba(36, 14, 65, 0.25);
							border-radius: 12px;
							// opacity: 0.25;
						}
						
					}
				}
                .mobile-btn{
                    position: absolute;
                    top:calc(50% - 20px);
                    right: 32px;
                    width: 40px;
                    height: 40px;
                    background: #753CBE;
                    border-radius: 50%;
                    z-index: 3;
                    .img{
                        width: 16px;
                        height: 12px;
                    }
                }
			}
            .more-btn{
                margin-top: 22px;
                width: 111px;
                height: 33px;
                border-radius: 16px;
                border: 1px solid #E5E5E5;
                margin: 22px auto 0 auto;
                
                text-align: center;
                font-weight: 300;
                font-size: 15px;
                color: #666666;
                line-height: 33px;
            }
		}
		// 装修案例
		.template {
            margin-top: 50px;
			// height: 930px;
			width: 100%;
			box-sizing: border-box;
            .template-list1,.carousel1 {
                display: none;
			}
            .template-list2{
                width: 100%;
                margin-top: 21px;
                padding: 0 12px;
                height: 263px;
                border-radius: 3px;
                opacity: 0.8;
                box-sizing: border-box;

            }
			.carousel2 {
				height: 265px;
                margin: 15px 0;
				.swiper {
                    height: 100%;
                    padding-right: 24px;
                    /deep/ .swiper-wrapper{
                        left: 12px;
                        padding-right: 24px;
                        .swiper-slide:last-child{
                            // padding-right: 48px;
                        }
                    }
				}
			}
            .more-btn{
                margin-top: 22px;
                width: 111px;
                height: 33px;
                border-radius: 16px;
                border: 1px solid #E5E5E5;
                margin: 22px auto 0 auto;
                
                text-align: center;
                font-weight: 300;
                font-size: 15px;
                color: #666666;
                line-height: 33px;
            }
		}
        .news{
            margin-top: 50px;
            margin-bottom: 50px;
            .box{
                margin-top: 21px;
                display: flex;
                align-items: center;
                flex-flow: column;
                .video{
                    width: 375px;
                    height: 220px;
                    background: #ABABAB;
                }
                .list{
                    z-index: 9;
                    width: 350px;
                    // height: 520px;
                    background: #FFFFFF;
                    // background: pink;
                    border-radius: 15px;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    margin-top: -50px;
                    .list-item:last-child{
                        box-shadow: none !important;
                    }
                    .list-item{
                        width: 100%;
                        padding: 10px 0px;
                        box-shadow: 0px 1px 0px 0px #E5E5E5;
                        cursor: pointer;
                        .info{
                            .title{
                                
                                font-weight: bold;
                                font-size: 12px;
                                color: #1B1D22;
                                line-height: 20px;
                                width: 80%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                
                            }
                            .time{
                                
                                font-weight: 400;
                                font-size: 10px;
                                color: #1B1D22;
                                margin-top: 6px;
                            }
                        }
                        .right{
                            font-size: 15px;
						    color: #753cbe;
                            font-weight: 600;
                        }
                    }
                    .list-item:hover{
                        .title{
                            color: #753CBE;
                        }
                    }
                }
                .more-btn{
                    margin-top: 22px;
                    width: 111px;
                    height: 33px;
                    border-radius: 16px;
                    border: 1px solid #E5E5E5;
                    margin: 22px auto 0 auto;
                    
                    text-align: center;
                    font-weight: 300;
                    font-size: 15px;
                    color: #666666;
                    line-height: 33px;
                }
            }
        }
	}
}
@media screen and (min-width: 900px) {
	.site {
		height: 100%;
		margin: 0;
		overflow: hidden;
		position: relative;
		// 整装
        .whole:hover{
            .swiper{
                /deep/ .swiper-slide{
                    .img{
                        transform: scale(1.2);
                    }
                    
                }
            }
        }
		.whole {
            margin-top: 130px;
			width: 100%;
			height: 1000px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            // background: pink;
			.carousel {
				width: 100%;
				height: 1000px;
				
				overflow: hidden;
				background: #fff;
				.swiper {
					width: 100%;
					height: 1000px;
					// background: #46ff56;
                    
                   
                    /deep/ .swiper-slide{
                        display: flex;
                        align-items: center;
                        .img-box{
                            border-radius: 0px 60px 60px 0px;
                            width: 1080px;
				            height: 1000px;
                            overflow: hidden;
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.6s;
                            }
                        }
                        .info1{
                            .cus-line{
                                width: 314px;
                            }
                            
                        }
                        .info2{
                            .cus-line{
                                width: 560px;
                            }
                            
                        }
                        .info3{
                            .cus-line{
                                width: 480px;
                            }
                            
                        }
                        .common {
                            // width: 40%;
                            flex: 1;
                            padding: 0px 50px 100px 50px;
                            height: 1000px;
                            box-sizing: border-box;
                            z-index: 9;
                            background: #fff;
                            .text1 {
                                // height: 126px;
                                font-family: Source Han Sans SC;
                                font-weight: 300;
                                font-size: 60px;
                                color: #1b1d22;
                                line-height: 60px;
                                margin-bottom: 25px;
                                cursor: pointer;
                            }
                            .text2 {
                                margin-top: 10px;
                                height: 77px;
                                line-height: 80px;
                                
                                font-weight: bold;
                                font-size: 80px;
                                color: #1b1d22;
                                cursor: pointer;
                            }
                            .cus-line {
                                height: 10px;
                                background: #753cbe;
                                margin-top:33px;
                            }
                            .tag1 {
                                width: 100%;
                                margin-top:45px;
                                .tag-item {
                                    height: 35px;
                                    
                                    font-weight: bold;
                                    font-size: 34px;
                                    color: #1b1d22;
                                }
                            }
                            .tag2 {
                                margin-top:52px;
                                width: 100%;
                                .tag-item {
                                    width: 200px;
                                    height: 66px;
                                    border: 1px solid #e5e5e5;
                                    
                                    font-weight: 400;
                                    font-size: 34px;
                                    color: #1b1d22;
                                }
                            }
                            
                        }
                    }
					/deep/ .swiper-pagination-customs {
						display: flex;
						justify-content: flex-start;
						padding-left: 850px;
						box-sizing: border-box;
						.text1 {
							height: 126px;
							font-family: DIN;
							font-weight: bold;
							font-size: 120px;
							color: #ffffff;
							line-height: 50px;
                            
						}
						.text2 {
							width: 5px;
							height: 100px;
							line-height: 50px;
							background: #ffffff;
							transform: skew(-30deg);
							margin-top: -10px;
                            margin-left: 20px;
                            margin-right: 30px;
						}
						.text3 {
							width: 40px;
							height: 94px;
							font-family: DIN;
							font-weight: 400;
							font-size: 94px;
							color: #ffffff;
							line-height: 126px;
						}
					}
				}
			}
            .options {
                position: absolute;
                bottom: 70px;
                left: 1130px;
                z-index: 9;
                .btn {
                    width: 125px;
                    height: 124px;
                    border-radius: 50%;
                    border: 2px solid #e5e5e5;
                    cursor: pointer;
                    .img1 {
                        font-size: 60px;
                        font-weight: bold;
                    }
                }
                .btn:hover {
                    border: 2px solid #753cbe;
                    .img1 {
                        color: #753cbe;
                    }
                }
                .btn-active{
                    border: 2px solid #753cbe;
                    .img1 {
                        color: #753cbe;
                    }
                }
            }
            
		}
		// 局改焕新家
        .part:hover{
            // transform: scale(1.1);
            background-size: 110% 110%;
        }
		.part {
            cursor: pointer;
            transition: all 0.6s;
            margin-top: 65px;
			height: 640px;
			width: 100%;
			background: url(../image/index/index_part_bg.jpg);
			background-size: 100% 100%;
			padding: 0 160px;
			box-sizing: border-box;
            background-position: center;
            overflow: hidden;
            position: relative;
            .mask{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
            }
			.title {
                z-index: 9;
				height: 80px;
				
				font-weight: bold;
				font-size: 80px;
				color: #ffffff;
				line-height: 80px;
			}
			.cus-line {
                z-index: 9;
                margin-top: 35px;
				width: 398px;
				height: 10px;
				background: #753cbe;
			}
			.text {
                z-index: 9;
                margin-top: 44px;
				width: 668px;
				height: 48px;
				
				font-weight: 400;
				font-size: 48px;
				color: #ffffff;
				line-height: 48px;
			}
		}
		// 样板间
		.sample-rom {
            margin-top: 130px;
			width: 100%;
			box-sizing: border-box;
           
			.carousel {
                position: relative;

				height: 880px;
                margin-top: 50px;
				.swiper {
					height: 100%;
					width: 100%;
                    // padding: 0 180px;
					/deep/.swiper-slide:last-child {
                        width: 1900px;
					}
                    /deep/.swiper-slide-active {
                        padding-left: 180px;
					}
                    /deep/.swiper-slide{
						width: 1200px;
					}
					.slide-item::after:hover {
						cursor: pointer;
					}
                    .slide-item:hover{
                        background-size: 110% 110%;
                        .mask {
							background: rgba(36, 14, 65, 0.15) !important;
						}
                    }
					.slide-item {
                        transition: all 0.6s;
						height: 100%;
						position: relative;
						border-radius: 60px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 1200px;
						.item-box {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 2;
							height: 100%;
							padding: 56px 70px;
							border-radius: 60px;
							width: 100%;
							height: 100%;
							box-sizing: border-box;
                            .title {
                                cursor: pointer;
                                width: 1000px;
                                
                                font-weight: bold;
                                font-size: 48px;
                                color: #ffffff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .img {
                                cursor: pointer;
                                width: 347px;
                                height: 347px;
                                opacity: 0.8;
                                margin: 0 auto;
                            }
							.bottom {
								width: 100%;
								.img2 {
                                    cursor: pointer;
									width: 65px;
									height: 65px;
								}
							}
						}
						
						.mask {
							z-index: 1;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							background: rgba(36, 14, 65, 0.25);
							border-radius: 60px;
							// opacity: 0.25;
						}
						
					}
                    .swiper-slide:last-child {
                        .mobile-btn{
                            display: none;
                        }
                    }
                    .swiper-slide:not(:last-child) {
                        .mobile-btn{
                            position: absolute;
                            top:calc(50% - 80px);
                            right: -125px;
                            width: 160px;
                            height: 160px;
                            background: #753CBE;
                            border-radius: 50%;
                            z-index: 3;
                            .img{
                                width: 70px;
                                height: 70px;
                            }
                        }
                    }
				}
                .mobile-btn{
                    cursor: pointer;
                    position: absolute;
                    top:calc(50% - 80px);
                    right: 395px;
                    width: 160px;
                    height: 160px;
                    background: #753CBE;
                    border-radius: 50%;
                    z-index: 3;
                    .img{
                        width: 70px;
                        height: 70px;
                        object-fit: cover;
                    }
                }
			}
            .more-btn{
                display: none;
            }
		}
		// 装修案例
		.template {
            margin-top: 130px;
			// height: 930px;
			width: 100%;
			box-sizing: border-box;

            .template-list2,.carousel2 {
                display: none;
			}
            .template-list1{
                margin-top: 21px;
                width: 100%;
                height: 710px;

            }
			.carousel1 {
				height: 700px;
                margin-top: 40px;
				.swiper {
                    height: 100%;
                    padding-bottom: 50px;
				}
			}
            .more-btn{
                display: none;
            }
		}
        .news{
            margin-bottom: 130px;
            margin-top: 116px;
            .box{
                margin-top: 40px;
                display: flex;
                align-items: center;
                position: relative;
                .video{
                    width: 1200px;
                    height: 675px;
                    background: #ABABAB;
                }
                .list{
                    z-index: 9;
                    width: 765px;
                    // height: 520px;
                    background: #FFFFFF;
                    // background: pink;
                    border-radius: 60px;
                    padding: 29px 55px;
                    box-sizing: border-box;
                    position: absolute;
                    margin: auto 0;
                    left: 1000px;
                    .list-item:last-child{
                        box-shadow: none !important;
                    }
                    .list-item{
                        width: 100%;
                        padding: 30px 16px;
                        box-shadow: 0px 1px 0px 0px #E5E5E5;
                        .info{
                            .title{
                                
                                font-weight: bold;
                                font-size: 24px;
                                color: #1B1D22;
                                line-height: 40px;
                                width: 80%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                cursor: pointer;
                                
                            }
                            .time{
                                
                                font-weight: 400;
                                font-size: 20px;
                                color: #1B1D22;
                            }
                        }
                        .right{
                            font-size: 20px;
						    color: #753cbe;
                            font-weight: 600;
                            cursor: pointer;

                        }
                    }
                    .list-item:hover{
                        .title{
                            color: #753CBE;
                        }
                    }
                }
                .more-btn{
                    display: none;
                }
            }
        }
	}
}
</style>

